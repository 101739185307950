<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="48"
      color="#79c61c"
    />
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <h1 class="mb-0 font-weight-bold">Enrollment Groups</h1>
        </div>
      </div>
  
      <div class="position-relative mb-6">
        <!-- <v-progress-circular class="rollup-loader" indeterminate :size="48" color="#79c61c" /> -->
        
        <v-data-table
          :class="`rollup-table`"
          :style="`--custom-table-height: ${rollupTableHeight}px`"
          :headers="ROLLUP_TABLE_HEADERS"
          :items="programRollupRows"
          @click:row="applyRollupFilterset"
          @current-items="(items) => filteredItems = items"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:header.available>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Available</p>
              <p class="w-100 ma-0">Available Program space</p>
            </div>
          </template>

          <template v-slot:header.enrolled>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Enrolled</p>
              <p class="w-100 ma-0">Total Enrollment requests</p>
            </div>
          </template>

          <template v-slot:header.remaining>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Remaining</p>
              <p class="w-100 ma-0">To be filled</p>
            </div>
          </template>

          <template v-slot:header.surplus>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Surplus</p>
              <p class="w-100 ma-0">Acreage above available space</p>
            </div>
          </template>

          <template v-slot:item.remaining="props">
            <v-chip v-if="props.value != '0'" color="red">{{ props.value }}</v-chip>
            <span v-else>{{ props.value }}</span>
          </template>

          <template v-slot:item.region="props">
            <span>{{ props.value['value'] }}</span>
          </template>

          <template v-slot:item.crops="props">
            <span>{{ formatCropNames(props.value['value']) }}</span>
          </template>
        </v-data-table>

        <v-simple-table class="rollup-table-summary">
          <a @click="toggleShowRollup">
            <img :style="`--flipped: ${showRollupTable ? '0deg' : '180deg'}`" :src="chevronUp">
          </a>

          <thead>
            <th></th>
            <th></th>
            <th>{{ lastRowSummary['available'] }} AC</th>
            <th>{{ lastRowSummary['enrolled'] }} AC</th>
            <th>{{ lastRowSummary['remaining'] }} AC</th>
            <th>{{ lastRowSummary['surplus'] }} AC</th>
          </thead>
        </v-simple-table>
      </div>
  
      <v-card outlined class="px-6 py-3">
        <v-data-table
          hide-default-footer
          disable-pagination
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :headers="HEADERS"
          :items="items"
          :items-per-page="10"
          @click:row="handleClick"
          class="enrollment-group-table"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
        >
          <template v-slot:top="props">
            <div
              v-bind="props"
              class="d-flex align-center justify-space-between mb-4"
            >
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ props.items.length }} Enrollment Group{{ props.items.length == 1 ? '' : 's' }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>

              <div class="d-flex justify-end">
                <v-select
                  :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allRegions"
                  v-model="selectedRegions"
                  placeholder="All Regions"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Regions <span>{{ selectedRegions.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ regionAbbrevToFull(item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueCrops"
                  v-model="selectedCrops"
                  placeholder="All Crops"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Crops <span>{{ selectedCrops.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:item.owner="props">
            <div v-bind="props">
              <div
                v-if="
                  props.value['name'] != null || props.value['email'] != null
                "
              >
                <p class="mb-0">{{ props.value["name"] }}</p>
                <p class="mb-0">{{ props.value["email"] }}</p>
              </div>
              <div v-else>
                <p>No Owner Specified</p>
              </div>
            </div>
          </template>

          <template v-slot:item.acres="props">
            <div v-bind="props">
              {{ Math.round(props.value) }}
            </div>
          </template>

          <template v-slot:item.status>
            <v-chip color="green">
              Enrollment pending
            </v-chip>
          </template>
          
          <template v-slot:item.crops="props">
            <span>{{ formatCropNames(props.value['value']) }}</span>
          </template>

          <template v-slot:item.regions="props">
            <span>{{ props.value['value'].join(', ') }}</span>
          </template>

          <template v-slot:item.availableAcres="props">
            <span>{{ parseInt(props.value.toFixed(0)).toLocaleString() }}</span>
          </template>

          <template v-slot:item.enrollmentRequestedAcres="props">
            <span>{{ parseInt(props.value.toFixed(0)).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import Permissions from "@/components/permissions/Permissions"
import { CROP_DISPLAY_NAME, CROP_KEY, US_STATES } from "@/constants"
import { listOverview } from "@/api/ContractGroupAPI"
import { mapState } from "vuex/dist/vuex.common.js"

const HEADERS = [
  { text: "Enrollment Group Name", value: "name" },
  { text: "Regions", value: "regions", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(r)) },
  { text: "Crops", value: "crops", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].some(c => v['value'].includes(c)) },
  { text: "Available AC", value: "availableAcres" },
  { text: "Requested AC", value: "enrollmentRequestedAcres" },
  { text: "Status", value: "status" },
]

const ROLLUP_TABLE_HEADERS = [
  { text: "Region", value: "region", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(r)) },
  { text: "Crop", value: "crops", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].some(c => v['value'].includes(c)) },
  { text: "", value: "available", sortable: false },
  { text: "", value: "enrolled", sortable: false },
  { text: "", value: "remaining", sortable: false },
  { text: "", value: "surplus", sortable: false },
]

export default {
  name: "ContractGroups",
  components: {
    Permissions,
  },

  data() {
    return {
      search: '',
      loading: true,
      error: false,
      filteredItems: [],
      selectedCrops: [],
      selectedRegions: [],
      groupOverviewData: [],
      groupProgramRollup: null,
      showRollupTable: true,
      HEADERS,
      ROLLUP_TABLE_HEADERS,
      chevronUp
    }
  },
  mounted() {
    this.getGroupOverview()
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
    }),
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 24 + 60 + 12

      // 24px of artificial padding at bottom
      const extra = 24

      return external + internal + extra
    },
    rollupTableHeight() {
      if (this.filteredItems != null && this.showRollupTable) {
        return this.filteredItems.length > 4
          ? 267
          : 58 + this.filteredItems.length * 44
      }

      return 58
    },
    allRegions() {
      const regs = []

      for (const { regions } of this.groupOverviewData) {
        for (const r of regions) {
          const found = US_STATES.find(({ text }) => text == r)
          if (found == null && !regs.includes('Canada')) regs.push('Canada')
          if (found != null && !regs.includes(found['value'])) regs.push(found['value'])
        }
      }

      regs.sort()

      return regs
    },
    allUniqueCrops() {
      const allCrops = []

      for (const { crops } of this.groupOverviewData) {

        const c = crops
          .map(c => Object.entries(CROP_KEY).find(([_, name]) => c == name)[0])
          .filter(c => c)
          .map(c => CROP_DISPLAY_NAME[c])
        
        for (const crop of c) {
          if (!allCrops.includes(crop)) allCrops.push(crop)
        }
      }

      allCrops.sort()

      return allCrops
    },
    lastRowSummary() {
      return {
        region: null,
        crop: null,
        available: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['available'].replaceAll(',', '')), 0
          ).toLocaleString(),
        enrolled: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['enrolled'].replaceAll(',', '')), 0
          ).toLocaleString(),
        surplus: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['surplus'].replaceAll(',', '')), 0
          ).toLocaleString(),
        remaining: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['remaining'].replaceAll(',', '')), 0
          ).toLocaleString()
      }
    },
    programRollupRows() {
      const rows = []

      if (this.groupProgramRollup == null) return rows

      for (const stateName in this.groupProgramRollup) {
        const found = US_STATES.find(({ text }) => text == stateName)
        const region = found != null ? found['value'] : 'Canada' // FIXME

        for (const cropIds in this.groupProgramRollup[stateName]['crops']) {
          const cropIdsArr = cropIds.split('-')
          const found = rows.find(r => r['cropIds'] == cropIds && r['region'] == stateName)
          if (found != null) {
            found['available'] += this.groupProgramRollup[stateName]['crops'][cropIds]['available']
            found['enrolled'] += this.groupProgramRollup[stateName]['crops'][cropIds]['enrolled']
          }
          else {
            rows.push({
              cropIds,
              region: {
                value: region,
                selectedRegions: this.selectedRegions
              },
              crops: {
                value: cropIdsArr.map(c => CROP_DISPLAY_NAME[c]),
                selectedCrops: this.selectedCrops
              },
              available: this.groupProgramRollup[stateName]['crops'][cropIds]['available'],
              enrolled: this.groupProgramRollup[stateName]['crops'][cropIds]['enrolled']
            })
          }
        }
      }

      for (const row of rows) {
        const { available, enrolled } = row
        row['available'] = available.toLocaleString()
        row['enrolled'] = enrolled.toLocaleString()
        row['remaining'] = available > enrolled ? (available - enrolled).toLocaleString() : "0"
        row['surplus'] = enrolled > available ? (enrolled - available).toLocaleString() : "0"
      }

      rows.sort((a, b) => 
        parseFloat(a['surplus'].replaceAll(',', '')) - parseFloat(b['surplus'].replaceAll(',', ''))
      )

      rows.sort((a, b) => 
        parseFloat(b['remaining'].replaceAll(',', '')) - parseFloat(a['remaining'].replaceAll(',', ''))
      )

      return rows
    },
    items() {
      const rows = []

      this.groupOverviewData.forEach(g => {
        const crops = g['crops']
          .map(c => Object.entries(CROP_KEY).find(([_, name]) => c == name)[0])
          .filter(c => c)
          .map(c => CROP_DISPLAY_NAME[c])
        
        const regions = []
        g['regions'].forEach(r => {
          const found = US_STATES.find(({ text }) => text == r)
          if (found == null && !regions.includes('Canada')) regions.push('Canada')
          if (found != null && !regions.includes(found['value'])) regions.push(found['value'])
        })

        rows.push({
          id: g['id'],
          name: g['name'],
          availableAcres: g['available'],
          enrollmentRequestedAcres: g['locked'],
          fields: g['fields_in_group'],
          status: '',
          regions: {
            value: regions,
            selectedRegions: this.selectedRegions
          },
          crops: {
            value: crops,
            selectedCrops: this.selectedCrops
          },
        })
      })

      rows.sort((a, b) => b['availableAcres'] - a['availableAcres'])
      rows.sort((a, b) => b['enrollmentRequestedAcres'] - a['enrollmentRequestedAcres'])
      return rows
    }
  },
  methods: {
    regionAbbrevToFull(region) {
      const found = US_STATES.find(({ value }) => value == region)
      if (found != null) return found['text']
      return region
    },
    applyRollupFilterset({ crops, region }) {
      this.selectedCrops = crops['value']
      this.selectedRegions = [region['value']]
    },
    formatCropNames(cropNameArr) {
      const lower = cropNameArr.join(', ').toLowerCase()
      return `${lower.slice(0, 1).toUpperCase()}${lower.slice(1)}`
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true
      
      const groupName = item['name'].toLowerCase()
      if (groupName.includes(search)) return true
      return false
    },
    toggleShowRollup() {
      this.showRollupTable = !this.showRollupTable
    },
    handleClick({ id }) {
      this.$router.push(`/enrollment-groups/${id}`)
    },
    getGroupOverview() {
      if (this.org != null && this.org['id'] != null && this.year != null) {
        listOverview({ org_node_id: this.org['id'], year: this.year })
        .then(({ data: { group_data, program_rollup } }) => {
          this.groupOverviewData = group_data
          this.groupProgramRollup = program_rollup
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
      }
    },
  },
  watch: {
    org() {
      this.getGroupOverview()
    }
  }
}
</script>

<style scoped>
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}
.rollup-table {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
}
.rollup-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: var(--custom-table-height, 210px);
}
.rollup-table-summary {
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #E5E7EB;
  border-top: none;
}
.rollup-table :deep(table),
.rollup-table-summary :deep(table) {
  table-layout: fixed;
}
.enrollment-group-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.rollup-table :deep(thead th) {
  height: 58px !important;
}
.rollup-table :deep(thead th > div > p:first-of-type) {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rollup-table :deep(thead th > div > p:last-of-type) {
  color: #374151;
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.rollup-table :deep(thead th:nth-of-type(1)),
.rollup-table-summary thead th:nth-of-type(1) {
  width: 100px;
}
.rollup-table :deep(thead th:nth-of-type(2)),
.rollup-table-summary thead th:nth-of-type(2) {
  width: 160px;
}
.rollup-table :deep(thead th:nth-of-type(n + 3)),
.rollup-table-summary thead th:nth-of-type(n + 3) {
  max-width: 196px;
  width: 100%;
}
.rollup-table :deep(tbody td) {
  height: 44px !important;
}
.rollup-table :deep(tr td:nth-of-type(2)) {
  white-space: nowrap;
}
.rollup-table-summary thead th {
  color: #20292F !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: normal;
  padding: 0 16px;
  height: 64px;
}
.rollup-table-summary thead th:nth-of-type(n+3) {
  font-weight: 700 !important;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
.v-data-table :deep(tr > td:nth-of-type(1)) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-chip.v-size--default {
  height: 20px !important;
}
.enrollment-group-table :deep(table) {
  table-layout: fixed;
}
.enrollment-group-table :deep(th:nth-of-type(1)) {
  width: 275px;
}
.enrollment-group-table :deep(th:nth-of-type(2)) {
  width: 129px;
}
.enrollment-group-table :deep(th:nth-of-type(3)) {
  width: 180px;
}
.enrollment-group-table :deep(th:nth-of-type(4)) {
  width: 120px;
}
.enrollment-group-table :deep(th:nth-of-type(5)) {
  width: 130px;
}
.enrollment-group-table :deep(th:nth-of-type(6)) {
  width: 180px;
}
.rollup-loader {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  z-index: 1;
}

/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  font-weight: 500;
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a) {
  display: flex;
  width: 96px;
  height: 64px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  cursor: pointer;
  justify-content: flex-end;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a img) {
  transform: rotate(var(--flipped, 0deg));
  transition: transform 0.25s ease-in-out;
  width: 36px;
  height: auto;
  margin-right: 36px;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
</style>
