<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />

    <!-- <ManageGroupSidebar
      :visible="showMangementSidebar"
      :entity="entityData"
      :fields="fieldsInGroup"
      :allFieldSupplySummaries="allFieldSupplySummaries"
      :allClientProfiles="allClientProfiles"
      @closeSidebar="handleManagementSidebarClose"
      @openLOIModal="handleLOIModalOpen"
      @renderGroupData="handleUpdateGroupData"
    />

    <LetterOfIntentModal
      v-if="openLOIModal"
      :data="dataForLOIModal"
      @close-modal="handleLOIModalClose"
      @generate-loi="generateLOI"
    />

    <ClientProfileModificationModal
      v-if="openProfileModificationModal"
      @close-modal="handleLOIModalClose"
    /> -->

    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="48"
      color="#79c61c"
    />
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <router-link to="/enrollment-groups">
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
          </router-link>
          <h1 class="mb-0 font-weight-bold">{{ groupDetailData["name"] }}</h1>
        </div>

        <div>
          <!-- <v-btn class="mr-3" :ripple="false" outlined height="44">
            <v-icon class="custom-icon">mdi-play-outline</v-icon>
            5 Min Training
          </v-btn> -->
  
          <v-btn disabled v-b-toggle.sidebar-right :ripple="false" outlined height="44">
            <v-icon class="mr-2">mdi-file-document-outline</v-icon>
            Manage Group & Contracts
          </v-btn>
        </div>
      </div>
  
      <div class="position-relative mb-6">
        <v-progress-circular class="rollup-loader" v-if="modifyingFssState" indeterminate :size="48" color="#79c61c" />
        
        <v-data-table
          :class="`rollup-table ${modifyingFssState ? 'fss-loading' : ''}`"
          :style="`--custom-table-height: ${rollupTableHeight}px`"
          :headers="ROLLUP_TABLE_HEADERS"
          :items="programRollupRows"
          @click:row="applyRollupFilterset"
          @current-items="(items) => filteredItems = items"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:header.available>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Available</p>
              <p class="w-100 ma-0">Available Program space</p>
            </div>
          </template>

          <template v-slot:header.enrolled>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Enrolled</p>
              <p class="w-100 ma-0">Total Enrollment requests</p>
            </div>
          </template>

          <template v-slot:header.enrolledFromGroup>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Enrolled from group</p>
              <p class="w-100 ma-0">Requested from this field set</p>
            </div>
          </template>

          <template v-slot:header.remaining>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Remaining</p>
              <p class="w-100 ma-0">To be filled</p>
            </div>
          </template>

          <template v-slot:header.surplus>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Surplus</p>
              <p class="w-100 ma-0">Acreage above available space</p>
            </div>
          </template>

          <template v-slot:item.remaining="props">
            <v-chip v-if="props.value != '0'" color="red">{{ props.value }}</v-chip>
            <span v-else>{{ props.value }}</span>
          </template>

          <template v-slot:item.region="props">
            <span>{{ props.value['value'] }}</span>
          </template>

          <template v-slot:item.crops="props">
            <span>{{ formatCropNames(props.value['value']) }}</span>
          </template>
        </v-data-table>

        <v-simple-table class="rollup-table-summary">
          <a @click="toggleShowRollup">
            <img :style="`--flipped: ${showRollupTable ? '0deg' : '180deg'}`" :src="chevronUp">
          </a>

          <thead>
            <th></th>
            <th></th>
            <th>{{ lastRowSummary['available'] }} AC</th>
            <th>{{ lastRowSummary['enrolled'] }} AC</th>
            <th>{{ lastRowSummary['enrolledFromGroup'] }} AC</th>
            <th>{{ lastRowSummary['remaining'] }} AC</th>
            <th>{{ lastRowSummary['surplus'] }} AC</th>
          </thead>
        </v-simple-table>
      </div>

      <v-card outlined class="px-6 py-4">
        <v-data-table
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          class="enrollment-table"
          :headers="HEADERS"
          :items="items"
          show-select
          hide-default-footer
          disable-pagination
          :search="search"
          :custom-filter="handleDataTableFiltering"
        >
          <template v-slot:top="props">
            <div v-bind="props" class="d-flex justify-space-between align-center mb-4">
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ props.items.length }} Field{{ props.items.length == 1 ? '' : 's' }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>
              <div class="d-flex justify-end">
                <v-select
                  :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allRegions"
                  v-model="selectedRegions"
                  placeholder="All Regions"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Regions <span>{{ selectedRegions.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ regionAbbrevToFull(item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueCrops"
                  v-model="selectedCrops"
                  placeholder="All Crops"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Crops <span>{{ selectedCrops.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedFarms.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allUniqueFarms"
                  v-model="selectedFarms"
                  placeholder="All Farms"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Farms <span>{{ selectedFarms.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${selectedStatuses.length == 0 ? 'all-selected' : ''}`"
                  solo
                  multiple
                  clearable
                  hide-details
                  :items="allStatuses"
                  v-model="selectedStatuses"
                  placeholder="All Statuses"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular">
                        Statuses <span>{{ selectedStatuses.length }}</span>
                      </p>
                    </div>
                  </template>

                  <template v-slot:item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.toLowerCase() == "locked" ? "Enrolled" : item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:header.requestEnrollment="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>This will request this field is added to your enrollment for this region and crop.</span>
            </v-tooltip>
          </template>

          <template v-slot:header.practicesDetail="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>These are the sustainable practices we have data for in our system for this field and crop. If you see something missing, it can be updated in the management practices or management practices worksheet found under the List View of Fields.</span>
            </v-tooltip>
          </template>

          <template v-slot:item.field="props">
            <div class="d-flex align-center py-3">
              <img class="thumbnail" :src="props.value['thumbnail']" @error="(e) => e.target.src = fallback">
              <p class="mb-0 ml-4 field-name">{{ props.value['name'] }}</p>
            </div>
          </template>

          <template v-slot:item.crops="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>
          
          <template v-slot:item.status="props">
            <v-chip v-if="props.value['value'] == AVAILABLE" color="gray">
              Available
            </v-chip>
            <v-chip v-else-if="props.value['value'] == LOCKED" color="green">
              Enrollment requested
            </v-chip>
            <v-chip v-else color="gray">
              Unavailable
            </v-chip>
          </template>

          <template v-slot:item.requestEnrollment="props">
            <!-- if disabled, show a tooltip or sm -->
             <!--  :disabled="props.value['disabled'] || modifyingFssState" -->
            <!-- <v-switch
              v-model="fieldsSetForEnrollment"
              :value="props.item['id']"
              disabled
              :ripple="false"
              hide-details
              inset
            /> -->
            <v-tooltip content-class="tooltip-no-transition" bottom max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-flex custom-toggler disabled">
                  <a
                    :class="`
                      px-4 py-3 d-flex align-center justify-center w-100 
                      ${fieldsSetForEnrollment.includes(props.item['id']) ? 'selected' : ''}
                    `"
                  >
                    Yes
                  </a>
                  <a
                    :class="`
                      px-4 py-3 d-flex align-center justify-center w-100 
                      ${!fieldsSetForEnrollment.includes(props.item['id']) ? 'selected' : ''}
                    `"
                  >
                    No
                  </a>
                </div>
              </template>

              <span>The ability to request enrollment for fields is coming soon! Right now, 'Yes' is selected if you've requested a field be enrolled.</span>
            </v-tooltip>
          </template>

          <template v-slot:item.practicesDetail="props">
            <p class="ma-0 text-capitalize">{{ props.value.join(', ') }}</p>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import fallback from "@/assets/images/fallback.png"
import Permissions from "@/components/permissions/Permissions"
import ManageGroupSidebar from "../components/contractGroups/ManageGroupSidebar.vue"
import LetterOfIntentModal from "../components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"
import { getGroupDetail, getGroupEditingDataset, updateFssStatuses } from "@/api/ContractGroupAPI"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"
import { mapState } from "vuex";
import ClientProfileModificationModal from "@/components/modals/ClientProfileModificationModal.vue";

// temp constants
const HEADERS = [
  { text: "Field", value: "field", filter: v => v['selectedFarms'].length == 0 || v['selectedFarms'].includes(v['farm']) },
  { text: "Region", value: "region" },
  { text: "Crop", value: "crops", filter: v => v['selectedCrops'].length == 0 || v['selectedCrops'].some(r => v['value'].includes(r)) },
  { text: "Acres", value: "acres" },
  { text: "Request Enrollment", value: "requestEnrollment", sortable: false },
  { text: "Practices", value: "practices" },
  { text: "Practices", value: "practicesDetail" },
  { text: "Status", value: "status", filter: v => v['selectedStatuses'].length == 0 || v['selectedStatuses'].includes(v['value']) },
]

const ROLLUP_TABLE_HEADERS = [
  { text: "Region", value: "region", filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(r)) },
  { text: "Crop", value: "crops", filter: v => 
    v['selectedCrops'].length == 0 || v['selectedCrops'].some(r => v['value'].map(vv => vv.toLowerCase()).includes(r.toLowerCase()))
  },
  { text: "", value: "available", sortable: false },
  { text: "", value: "enrolled", sortable: false },
  { text: "", value: "enrolledFromGroup", sortable: false },
  { text: "", value: "remaining", sortable: false },
  { text: "", value: "surplus", sortable: false },
]

const AVAILABLE = "available"
const NEEDS_SUBMISSION = "needs_submission"
const ENROLLMENT_REQUESTED = "enrollment_requested"
const LOCKED = "locked"
const OPEN = "open"
const MATCHED = "matched"

const SET_TO_LOCKED = "set_to_locked"
const SET_TO_AVAILABLE = "set_to_available"

export default {
  name: "ContractGroupDetail",
  components: {
    Permissions,
    ManageGroupSidebar,
    LetterOfIntentModal,
    ClientProfileModificationModal,
  },
  data() {
    return {
      filteredItems: [],
      search: '',
      selectedCrops: [],
      selectedFarms: [],
      selectedRegions: [],
      selectedStatuses: [],
      showRollupTable: true,
      modifyingFssState: false,
      openProfileModificationModal: false,
      showMangementSidebar: false,
      openLOIModal: false,
      loading: true,
      error: false,
      groupDetailData: null,
      groupProgramRollup: {
        order_details: [],
        order_allocations_within_group: {}
      },
      dataForLOIModal: null,
      dataForProfileModificationModal: null,
      allFieldSupplySummaries: [],
      allClientProfiles: [],
      fieldsSetForEnrollment: [],
      HEADERS,
      ROLLUP_TABLE_HEADERS,
      AVAILABLE,
      NEEDS_SUBMISSION,
      ENROLLMENT_REQUESTED,
      LOCKED,
      OPEN,
      MATCHED,
      fallback,
      chevronUp,
      allStatuses: [AVAILABLE, LOCKED]
    }
  },
  mounted() {
    this.getGroupData()
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
    }),
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 32 + 60 + 12

      // 24px of artificial padding at bottom
      const extra = 24

      return external + internal + extra
    },
    rollupTableHeight() {
      if (this.filteredItems != null && this.showRollupTable) {
        return this.filteredItems.length > 4
          ? 267
          : 58 + this.filteredItems.length * 44
      }

      return 58
    },
    allRegions() {
      const regs = []

      if (this.groupDetailData == null) return regs

      for (const { region } of this.groupDetailData['fieldsupply']) {
        const found = US_STATES.find(({ text }) => text == region)
        if (found == null && !regs.includes('Canada')) regs.push('Canada')
        if (found != null && !regs.includes(found['value'])) regs.push(found['value'])
      }

      regs.sort()

      return regs
    },
    allUniqueFarms() {
      const allFarms = []

      if (this.groupDetailData == null) return allFarms

      for (const { field_farm } of this.groupDetailData['fieldsupply']) {
        if (!allFarms.includes(field_farm)) allFarms.push(field_farm)
      }

      return allFarms.sort()
    },
    allUniqueCrops() {
      const allCrops = []

      if (this.groupDetailData == null) return allCrops

      for (const { crop } of this.groupDetailData['fieldsupply']) {
        if (!allCrops.includes(crop)) allCrops.push(crop)
      }

      return allCrops.sort()
    },
    programRollupRows() {
      const rowsByRegion = {}

      for (const { id, region, params, program_allotment } of this.groupProgramRollup['order_details']) {
        if (program_allotment.length == 0) continue

        for (const regName of region) {
          const found = US_STATES.find(({ text }) => text == regName)
          const reg = found != null ? found['value'] : 'Canada' // FIXME
          if (!(reg in rowsByRegion)) rowsByRegion[reg] = {
            crops: [
              {
                cropIds: params['crop_id'] || [],
                crops: params['crop_id'] ? params['crop_id'].map(c => CROP_DISPLAY_NAME[c]) : '',
                available: program_allotment[0]['total_allotment'],
                enrolled: program_allotment[0]['allocated'],
                enrolledFromGroup: this.groupProgramRollup['order_allocations_within_group'][id]
              }
            ]
          }
          else {
            const found = rowsByRegion[reg]['crops'].find(({ cropIds }) => cropIds.every(id => params['crop_id'].includes(id)))

            if (found == null) {
              rowsByRegion[reg]['crops'].push({
                cropIds: params['crop_id'],
                crops: params['crop_id'].map(c => CROP_DISPLAY_NAME[c]),
                available: program_allotment[0]['total_allotment'],
                enrolled: program_allotment[0]['allocated'],
                enrolledFromGroup: this.groupProgramRollup['order_allocations_within_group'][id]
              })
            }
            else {
              found['available'] += program_allotment[0]['total_allotment'],
              found['enrolled'] += program_allotment[0]['allocated']
              found['enrolledFromGroup'] += this.groupProgramRollup['order_allocations_within_group'][id]
            }
          }
        }
      }

      const rows = []

      for (const region in rowsByRegion) {
        for (let i=0; i<rowsByRegion[region]['crops'].length; i++) {
          const { crops, enrolled, available, enrolledFromGroup } = rowsByRegion[region]['crops'][i]

          rows.push({
            region: {
              value: region,
              selectedRegions: this.selectedRegions
            },
            crops: {
              value: crops,
              selectedCrops: this.selectedCrops
            },
            available: available.toLocaleString(),
            enrolled: enrolled.toLocaleString(),
            enrolledFromGroup: Number(enrolledFromGroup.toFixed(0)).toLocaleString(),
            surplus: available - enrolled <= 0 ? Math.abs(available - enrolled).toLocaleString() : '0',
            remaining: available - enrolled >= 0 ? Math.abs(available - enrolled).toLocaleString() : '0'
          })
        }
      }

      rows.sort((a, b) => 
        parseFloat(a['surplus'].replaceAll(',', '')) - parseFloat(b['surplus'].replaceAll(',', ''))
      )

      rows.sort((a, b) => 
        parseFloat(b['remaining'].replaceAll(',', '')) - parseFloat(a['remaining'].replaceAll(',', ''))
      )

      rows.sort((a, b) =>
        parseFloat(b['enrolledFromGroup'].replaceAll(',', '')) - parseFloat(a['enrolledFromGroup'].replaceAll(',', ''))
      )

      return rows
    },
    lastRowSummary() {
      return {
        region: null,
        crop: null,
        available: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['available'].replaceAll(',', '')), 0
          ).toLocaleString(),
        enrolled: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['enrolled'].replaceAll(',', '')), 0
          ).toLocaleString(),
        enrolledFromGroup: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['enrolledFromGroup'].replaceAll(',', '')), 0
          ).toLocaleString(),
        surplus: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['surplus'].replaceAll(',', '')), 0
          ).toLocaleString(),
        remaining: this.filteredItems
          .reduce((acc, cur) => 
            acc += parseFloat(cur['remaining'].replaceAll(',', '')), 0
          ).toLocaleString()
      }
    },
    entityData() {
      if (this.groupDetailData == null || this.org == null) return null

      return {
        id: this.groupDetailData["id"],
        org: this.org["id"],
        year: this.year,
        name: this.groupDetailData["name"],
        notes: this.groupDetailData["notes"],
        cpProfiles: this.groupDetailData["cp_profiles"],
        growerProfiles: this.groupDetailData["grower_profiles"],
        generatedContracts: this.groupDetailData["generatedContracts"],
      }
    },
    items() {
      if (this.groupDetailData == null) return []

      return this.groupDetailData['fieldsupply'].map(fs => (
        {
          id: fs['id'],
          field: {
            name: fs['field_name'],
            thumbnail: fs['field_thumbnail'],
            farm: fs['field_farm'],
            selectedFarms: this.selectedFarms
          },
          acres: fs['acreage'],
          crops: {
            value: fs['crop'],
            selectedCrops: this.selectedCrops
          },
          status: {
            value: fs['status'],
            selectedStatuses: this.selectedStatuses
          },
          requestEnrollment: {
            disabled: fs['matched_program'] == null,
            program: fs['matched_program']
          },
          practices: fs['practices'].length,
          practicesDetail: fs['practices']
            .map(p => p.includes(': ') ? p.split(': ')[1] : p)
            .map(p => EVIDENCING_TYPE_CHOICES.find(({ value }) => p == value || null))
            .filter(p => p)
            .map(p => p['name']),
          region: fs['region'] ? US_STATES.find(({ text }) => text == fs['region'])['value'] : null,
          submitDate: null,
          deadline: null
        }
      ))
    },
    fieldsInGroup() {
      if (this.groupDetailData == null) return []

      return this.groupDetailData['fieldsupply'].map(fs => (
        {
          id: fs['id'],
          field: {
            name: fs['field_name'],
            thumbnail: fs['field_thumbnail']
          },
          acres: fs['acreage'],
          crops: fs['crop'],
          status: fs['status'],
          requestEnrollment: {
            disabled: fs['matched_program'] == null
          },
          group: fs['contract_group'] || null
        }
      ))
    },
    fssCommittedForEnrollment() {
      return this.groupDetailData['fieldsupply']
      .filter(({ status }) => status == LOCKED)
      .map(({ id }) => id)
    }
  },
  methods: {
    regionAbbrevToFull(region) {
      const found = US_STATES.find(({ value }) => value == region)
      if (found != null) return found['text']
      return region
    },
    applyRollupFilterset({ crops, region }) {
      this.selectedCrops = crops['value'].map(c => c.toLowerCase())
      this.selectedRegions = [region['value']]
    },
    formatCropNames(cropNameArr) {
      const lower = cropNameArr.join(', ').toLowerCase()
      return `${lower.slice(0, 1).toUpperCase()}${lower.slice(1)}`
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true

      const fieldName = item['field']['name'].toLowerCase()
      if (fieldName.includes(search)) return true
      return false
    },
    toggleShowRollup() {
      this.showRollupTable = !this.showRollupTable
    },
    handleUpdateGroupData(data) {
      this.groupDetailData = data
    },
    handleProfileModificationModalOpen() {
      this.dataForProfileModificationModal = {}
      this.openProfileModificationModal = true
    },
    handleProfileModificationModalClose() {
      this.dataForProfileModificationModal = null
      this.openProfileModificationModal = false
    },
    handleLOIModalOpen(data) {
      this.dataForLOIModal = {
        ...data,
        org: this.org,
      }
      this.openLOIModal = true
    },
    handleLOIModalClose() {
      this.dataForLOIModal = null
      this.openLOIModal = false
    },
    handleManagementSidebarClose(cancelled) {
      if (!cancelled) {
      }

      this.showMangementSidebar = false
    },
    async generateLOI(payload) {
      //
      // TEMP
      //
      return
      const appendedPayload = {
        ...payload,
        fss_ids: this.groupDetailData["fieldsupply"].map(({ id }) => id),
        year: this.groupDetailData["year"],
      }

      this.openLOIModal = false
      this.showLoading = true
      FieldsAPI.generateLOI(appendedPayload).then(r => {
        alert("LOI Created!")
        // this.fetchClientProfile()
        // this.showLoading = false
        // this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        // this.showSuccessAlert = true
        // setTimeout(() => {
        //   this.showSuccessAlert = false
        // }, 10000)
      })
    },
    getGroupData() {
      this.loading = true

      if (this.org != null && this.org['id'] != null && this.year != null) {
        getGroupDetail({ org_node_id: this.org['id'], year: this.year, group_id: this.$router.currentRoute.params['id'] })
        .then(({ data: { group_data, program_rollup } }) => {
          this.groupDetailData = group_data
          this.groupProgramRollup = program_rollup

          this.fieldsSetForEnrollment = group_data['fieldsupply']
          .filter(({ status }) => status == LOCKED)
          .map(({ id }) => id)

          this.loading = false

          //
          // TEMP disable getting the sidebar information
          //
          // getGroupEditingDataset({ org_node_id: this.org['id'], year: this.year, })
          // .then(({ data }) => {
          //   this.allFieldSupplySummaries = data["fss"]
          //   this.allClientProfiles = data["client_profiles"].sort((a, b) =>
          //     a["poc_email"].localeCompare(b["poc_email"])
          //   )
          //   this.loading = false
          // })
          // .catch(e => {
          //   this.loading = false
          //   this.error = e
          // })
      })}
    },
  },
  watch: {
    org() {
      this.getGroupData()
    },
    fieldsSetForEnrollment(curr) {
      //
      // TEMP disable FSS editing
      //
      return
      if (curr != null) {
        const fss = []
        let action = null

        if (curr.length == this.fssCommittedForEnrollment.length) return

        if (curr.length > this.fssCommittedForEnrollment.length) {
          fss.push(...curr.filter(id => !this.fssCommittedForEnrollment.includes(id)))
          action = SET_TO_LOCKED
        }

        if (curr.length < this.fssCommittedForEnrollment.length) {
          fss.push(...this.fssCommittedForEnrollment.filter(id => !curr.includes(id)))
          action = SET_TO_AVAILABLE
        }

        this.modifyingFssState = true

        updateFssStatuses({ fss, action, group_id: this.$router.currentRoute.params['id'] })
        .then(({ data }) => {
          this.handleUpdateGroupData(data)
          this.modifyingFssState = false
        })
        .catch(e => console.log('error!'))
      }
    }
  }
}
</script>

<style>
.tooltip-no-transition {
  transition: none !important;
}
</style>

<style scoped>
:deep(th i) {
  margin-left: 4px;
  font-size: 16px !important;
}
.capitalize {
  text-transform: capitalize;
}
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}
.rollup-table {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
}
.rollup-table-summary {
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #E5E7EB;
  border-top: none;
}
.rollup-table :deep(table),
.rollup-table-summary :deep(table) {
  table-layout: fixed;
}
.rollup-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: var(--custom-table-height, 210px);
}
.enrollment-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.rollup-table :deep(thead th) {
  height: 58px !important;
}
.rollup-table :deep(thead th > div > p:first-of-type) {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rollup-table :deep(thead th > div > p:last-of-type) {
  color: #374151;
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.rollup-table :deep(thead th:nth-of-type(1)),
.rollup-table-summary thead th:nth-of-type(1) {
  width: 100px;
}
.rollup-table :deep(thead th:nth-of-type(2)),
.rollup-table-summary thead th:nth-of-type(2) {
  width: 160px;
}
.rollup-table :deep(thead th:nth-of-type(n + 3)),
.rollup-table-summary thead th:nth-of-type(n + 3) {
  max-width: 196px;
  width: 100%;
}
.rollup-table :deep(tbody td) {
  height: 44px !important;
}
.rollup-table :deep(tr td:nth-of-type(2)) {
  white-space: nowrap;
}
.rollup-table-summary thead th {
  color: #20292F !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: normal;
  padding: 0 16px;
  height: 64px;
}
.rollup-table-summary thead th:nth-of-type(n+3) {
  font-weight: 700 !important;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox) {
  width: 24px;
  margin: 0 auto !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox i) {
  margin-left: 0 !important;
}
:deep(.v-simple-checkbox i::before) {
  font-size: 24px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table:not(.rollup-table) :deep(tr > th:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(2)) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-chip.v-size--default {
  height: 20px !important;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.practices-list {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}
.field-name {
  width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
}
.enrollment-table :deep(table) {
  table-layout: fixed;
}
.enrollment-table :deep(th:nth-of-type(1)) {
  width: 64px !important;
}
.enrollment-table :deep(th:nth-of-type(2)) {
  width: 192px;
}
.enrollment-table :deep(th:nth-of-type(3)) {
  width: 89px;
}
.enrollment-table :deep(th:nth-of-type(4)) {
  width: 118px;
}
.enrollment-table :deep(th:nth-of-type(5)) {
  width: 90px;
}
.enrollment-table :deep(th:nth-of-type(6)) {
  width: 205px;
}
.enrollment-table :deep(th:nth-of-type(7)) {
  width: 105px;
}
.enrollment-table :deep(th:nth-of-type(8)) {
  width: 300px;
}
.enrollment-table :deep(th:nth-of-type(9)) {
  width: 138px;
}
.enrollment-table :deep(tr td:nth-of-type(9)) {
  padding: 0 4px;
}
.enrollment-table :deep(tr td:nth-of-type(8) > p) {
  color: #4B5563;
  font-size: 14px;
  line-height: 16px;
}
.v-chip :deep(span) {
  font-size: 12px;
}
.fss-loading {
  opacity: 0.5;
}
.rollup-loader {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  z-index: 1;
}

.custom-toggler {
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  width: 148px;
  overflow: hidden;
}
.custom-toggler.disabled {
  opacity: 0.5;
}
.custom-toggler.disabled a {
  pointer-events: none;
}
.custom-toggler a {
  color: #374151;
}
.custom-toggler a:first-of-type {
  border-right: 1px solid #E5E7EB;
}
.custom-toggler a:hover {
  opacity: 0.5;
}
.custom-toggler a.selected {
  background: #61AF02;
  color: white;
}

/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  font-weight: 500;
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-input.v-select :deep(.v-select__slot label) {
  top: 7px;
}
.v-input.v-select :deep(.v-input__append-inner) {
  margin-top: 0px !important;
}
.v-chip.gray {
  background: #F3F4F6 !important;
}
.v-chip.gray :deep(span) {
  color: #374151;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a) {
  display: flex;
  width: 96px;
  height: 64px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  cursor: pointer;
  justify-content: flex-end;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a img) {
  transform: rotate(var(--flipped, 0deg));
  transition: transform 0.25s ease-in-out;
  width: 36px;
  height: auto;
  margin-right: 36px;
}
.custom-icon {
  font-size: 28px;
}
.back-icon {
  font-size: 32px;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
</style>
